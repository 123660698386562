<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="6">
        <MatriculaDRETextField v-model="inscricaoMUF.matricula" :label="'Matrícula'"/>
      </v-col>
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import MatriculaDRETextField from "@/components/pages/Inscricao/MatriculaDRETextField";

export default {
  components: {MatriculaDRETextField, EscolhaOpcaoSelect},
  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },
  data() {
    return {
      inscricaoMUF: {
        inscricao: null,
        matricula: '',
      }
    };
  },

  watch: {
    inscricaoMUF: {
      handler(){
        this.inscricaoMUF.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoMUF);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
};
</script>
